import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import HeaderIconUser from '../IconUser';

// 已经登录
export default function HeaderUserLogin() {
    const { locale = 'us' } = useRouter();
    return (
        <EcoLink
            href={`/${locale}/my-overview`}
            className="HeaderV2-r-item HeaderV2-r-item-UserLogin"
            aria-label={'user'}
            dataLayer={{
                event: 'uaEvent',
                event_name: 'top_function',
                button_name: 'Brand_My Account_Logged in',
            }}
        >
            <HeaderIconUser />
        </EcoLink>
    );
}
