import { checkIsWithinTime, clearExpiredRecord, recordTime } from '@/app/eflayout/Header/time';
import gtm from '@/utils/gtm';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import HeaderIconUser from '../IconUser';
import HeaderUserTipsPc from './HeaderUserTipsWin/HeaderUserTipsPc';
import './HeaderUserTipsWin/index.scss';
// 没有登录
export default function HeaderUserNone() {
    const router = useRouter();
    const [isOpen1, setIsOpen1] = useState(false); //用于悬浮
    const [isOpen2, setIsOpen2] = useState(false); //用于点击|24小时
    const { query, pathname, locale = 'us' } = router;
    // 端点击个人中心
    const enterUserHandle = () => {
        gtm.push({
            event: 'uaEvent',
            event_name: 'top_function',
            button_name: 'Brand_My Account_Logged out',
        });
        // 跳转链接添加inviCode
        const { inviteCode } = query;
        const concatInviCode = inviteCode && pathname === '/referral-rewards';
        router.push(`/${locale}/login${concatInviCode ? `?inviteCode=${inviteCode}&redirect=${encodeURIComponent(location.href)}` : ''}`);
    };
    // 打开
    const openCallback = () => {
        setIsOpen1(true);
    };
    // 关闭
    const closeCallback1 = () => {
        setIsOpen1(false);
    };
    // 关闭
    const closeCallback2 = () => {
        setIsOpen2(false);
        setIsOpen1(false);
        recordTime(locale); //24小时内出现过
    };
    const compCls = classNames('HeaderV2-r-item HeaderV2-r-item-UserNone', {
        [`HeaderV2-r-item-open1`]: isOpen1,
        [`HeaderV2-r-item-open2`]: isOpen2,
    });
    // 10s后关闭
    const closeWinFor10SecondHandle = () => {
        setTimeout(() => {
            setIsOpen2(false);
        }, 10 * 1000);
    };
    const initTipsWinFor24 = () => {
        // 仅在首页展示
        if (pathname !== `/`) {
            return;
        }
        // 不在24小时内或者不符合弹窗规则，则跳过
        const isFlag = checkIsWithinTime(locale);

        if (isFlag) {
            return;
        }

        // 清除超过24h的弹窗记录站点
        clearExpiredRecord();
        setIsOpen2(true);
        closeWinFor10SecondHandle();
    };
    useEffect(() => {
        initTipsWinFor24();
    }, []);

    return (
        <div className={compCls} onMouseEnter={openCallback} onMouseLeave={closeCallback1}>
            <button className="HeaderV2-r-UserNone-link" onClick={enterUserHandle} aria-label={'user'}>
                <HeaderIconUser />
            </button>
            <HeaderUserTipsPc closeCallback={closeCallback2} />
        </div>
    );
}
