import { i18nText } from '@elem/compCommon/ListCardA/i18nText';
import classNames from 'classnames';
import EcoImg from 'eco-design/base/eco-img';
import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import CardMoreA from '../CardMoreA';
// item 可能是 1级的,也可能是 2级的,根据 contentType === 数字1 来区分
const getSubsHandle = (item1: any) => {
    const item2 = JSON.parse(JSON.stringify(item1));
    const subs1 = item2.subs || [];
    let subs2 = [];
    const firstSubsItem1 = subs1[0] || {};

    if (item2.contentType === 4) {
        subs2 = [
            {
                name: item2.name,
                hideNameFlag: item2.hideNameFlag,
                buttonName: item2.buttonName,
                buttonUrl: item2.buttonUrl,
                subs: [item2],
            },
        ];
    } else if ([2, 3, 4, 5].includes(firstSubsItem1.contentType)) {
        // 1是菜单,5场景,6是更多按钮卡片
        // 商品,场景
        subs2 = [
            {
                name: item2.name,
                hideNameFlag: item2.hideNameFlag,
                buttonName: item2.buttonName,
                buttonUrl: item2.buttonUrl,
                subs: subs1,
            },
        ];
    } else {
        subs2 = subs1;
    }

    return subs2;
};
export default function NavSceneA({ item = {}, className }: { item?: any; className?: string }) {
    const { locale = 'us' } = useRouter();
    const labelTextA = i18nText[locale] || i18nText['en'] || {};
    const subs1 = getSubsHandle(item || {});
    const otherEntranceFlag = item.otherEntranceFlag;
    const otherEntrances = item.otherEntrances || [];
    const prefixCls1 = 'HeaderV2-NavSceneA-item1';
    const prefixCls2 = 'HeaderV2-NavSceneA-item2';
    const canSetMainArray = [2, 3, 4]; //可以设置主推产品的类型
    const shopifyOrigin = `https://${locale}.ecoflow.com`;
    let canSetMainIndex = true;
    // 埋点-238-官网详情页
    const dataLayer_238 = {
        event: 'uaEvent',
        event_name: 'jump_button',
        position: 'Collections_navigationBar',
        //button_name: '$buttonName_navigationBar',
        //url: '$linkUrl',
    };
    // 埋点-108-buy
    const dataLayer_108 = {
        event: 'uaEvent',
        event_name: 'buy',
        position: 'Collections_navigationBar',
        //product_name: '$productName',
    };
    // 埋点-107-select_product
    const dataLayer_107 = {
        event: 'uaEvent',
        event_name: 'select_product',
        // list_name: '$listName',
        // product_name: '$productName',
        // button_name: '$buttonName_navigationBar',
    };
    return (
        <div className={`HeaderV2-NavSceneA ${className}`}>
            {subs1.map((item1: any, index1: number) => {
                const subs2 = item1?.subs || [];
                const length = subs2.length;
                // 场景
                const isScene = subs2.find((item: any) => {
                    return item.contentType === 5;
                });

                // 主推图
                const hasMain1 = subs2.find((item: any) => {
                    return item.mainFlag === true && canSetMainArray.includes(item.contentType);
                });

                // 可以设置主推图
                const canSetMainFlag = subs2.find((item: any) => {
                    return canSetMainArray.includes(item.contentType);
                });
                const hasMain = hasMain1 && length >= 6 && canSetMainFlag && canSetMainIndex;
                // 只能出现的第1个设置为主推,后面的即使设置了也不行
                if (canSetMainFlag && canSetMainIndex) {
                    canSetMainIndex = false;
                }
                const indexA = index1 + 1;
                const cardBtnItemA1 = {
                    url: item1.buttonUrl,
                    name: item1.buttonName,
                };
                const cardBtnItemB1 = subs2.find((item: any) => {
                    return item.contentType === 6;
                });
                const cardBtnItemB2 = cardBtnItemB1 || {};
                const showMoreBtn1 = !!cardBtnItemA1.url && !!cardBtnItemA1.name;
                const showMoreBtn2 = !!cardBtnItemB2.url && !!cardBtnItemB2.name;
                const cardItemBtn = showMoreBtn1 ? cardBtnItemA1 : cardBtnItemB2;
                const onlyShowH5Btn = !showMoreBtn1 && showMoreBtn2; //btn1无时,btn2有时;只展示1个按钮逻辑

                const compCls1 = classNames(prefixCls1, {
                    [`${prefixCls1}-canSetMain`]: canSetMainFlag,
                    [`${prefixCls1}-length-${length}`]: length,
                    [`${prefixCls1}-scene-y`]: isScene, //场景
                    [`${prefixCls1}-scene-n`]: !isScene, //非场景
                    [`${prefixCls1}-main-y`]: hasMain, //有主图
                    [`${prefixCls1}-main-n`]: !hasMain, //没主图
                    [`${prefixCls1}-index-${indexA}`]: indexA, //下标
                });
                const moreBthUrl = cardItemBtn.url || '';
                const moreDataLayer_ef1 = {
                    ...dataLayer_238,
                    button_name: `${cardItemBtn.name}_navigationBar`,
                    url: moreBthUrl,
                };
                const moreDataLayer_shopify1 = {
                    ...dataLayer_108,
                    product_name: `${item1.name || cardItemBtn.name}`,
                };
                const moreDataLayer1 = moreBthUrl.includes(shopifyOrigin) ? moreDataLayer_shopify1 : moreDataLayer_ef1;

                return (
                    <div className={compCls1} key={index1}>
                        <div className="HeaderV2-NavSceneA-item1-h">
                            {!item1.hideNameFlag && !!item1.name && <div className="HeaderV2-NavSceneA-item1-h-btn1">{item1.name}</div>}
                            {(showMoreBtn1 || showMoreBtn2) && (
                                <EcoLink
                                    className={`HeaderV2-NavSceneA-item1-h-btn2${onlyShowH5Btn ? ' HeaderV2-NavSceneA-item1-h-onlyShowH5Btn' : ''}`}
                                    href={moreBthUrl}
                                    dataLayer={moreDataLayer1}
                                >
                                    {cardItemBtn.name}
                                    <span className="HeaderV2-NavSceneA-icon"></span>
                                </EcoLink>
                            )}
                        </div>
                        <div className="HeaderV2-NavSceneA-item1-c">
                            {subs2.map((item2: any, index2: number) => {
                                const itemLabel = item2.label;
                                const contentType = item2.contentType;
                                const compCls2 = classNames(prefixCls2, {
                                    [`${prefixCls2}-contentType-${contentType}`]: contentType,
                                    [`${prefixCls2}-type-main`]: item2.mainFlag,
                                    [`${prefixCls2}-type-other`]: !item2.mainFlag,
                                    [`${prefixCls2}-label-${itemLabel}`]: itemLabel,
                                    [`${prefixCls2}-${index2 + 1}`]: true,
                                });
                                const isMoreBtn = contentType === 6;
                                let itemHref = item2.href || item2.url;
                                const itemTitle = item2.heading || item2.name;
                                let dataLayer_card: any = {
                                    ...dataLayer_107,
                                    list_name: `${item1.name || 'none'}`,
                                    product_name: itemTitle,
                                    button_name: `${itemTitle}_navigationBar`,
                                };
                                if (contentType === 2) {
                                    // 官网产品链接
                                    itemHref = `/${locale}/${item2.url || ''}`;
                                } else if (contentType === 4) {
                                    // shopify链接
                                    itemHref = `https://${locale}.ecoflow.com/products/${item2.url || ''}`;
                                } else if (contentType === 5) {
                                    // 场景
                                    itemHref = item2.url;
                                }
                                if ((itemHref || '').includes(shopifyOrigin)) {
                                    dataLayer_card = {
                                        ...dataLayer_108,
                                        product_name: itemTitle,
                                    };
                                }

                                return (
                                    <Fragment key={index2}>
                                        {isMoreBtn && (
                                            <>
                                                <CardMoreA buttonUrl={item2.url} buttonName={item2.name} className={prefixCls2} dataLayer={dataLayer_card} />
                                            </>
                                        )}
                                        {!isMoreBtn && (
                                            <EcoLink className={compCls2} href={itemHref} dataLayer={dataLayer_card}>
                                                <EcoImg pc={item2.pictureUrl} className="HeaderV2-NavSceneA-item2-img" />
                                                <div className="HeaderV2-NavSceneA-item2-msg">
                                                    <div className="HeaderV2-NavSceneA-item2-label">{labelTextA[itemLabel]}</div>
                                                    <div className="HeaderV2-NavSceneA-item2-title">{itemTitle}</div>
                                                    {item2.subtitle && (
                                                        <div
                                                            className="HeaderV2-NavSceneA-item2-desc"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item2.subtitle,
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                            </EcoLink>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </div>
                        <div className="HeaderV2-NavSceneA-bottom-border"></div>
                    </div>
                );
            })}

            {otherEntranceFlag && otherEntrances.length && (
                <div className="HeaderV2-NavSceneA-item1-f">
                    {otherEntrances?.map((item3: any, index3: number) => {
                        const moreBthUrl = item3.entranceUrl || '';
                        const moreDataLayer_ef1 = {
                            ...dataLayer_238,
                            button_name: `${item3.entranceName}_navigationBar`,
                            url: moreBthUrl,
                        };
                        const moreDataLayer_shopify1 = {
                            ...dataLayer_108,
                            product_name: `${item3.entranceName}`,
                        };
                        const moreDataLayer1 = moreBthUrl.includes(shopifyOrigin) ? moreDataLayer_shopify1 : moreDataLayer_ef1;
                        return (
                            <Fragment key={index3}>
                                <EcoLink className="HeaderV2-NavSceneA-item1-f-item" href={item3.entranceUrl} dataLayer={moreDataLayer1}>
                                    {item3.entranceName}
                                    <span className="HeaderV2-NavSceneA-icon"></span>
                                </EcoLink>
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
