'use client';
import { getIsPadWidth } from '@elem/utils/devices';
import { NewMySites } from 'elem/compPage/my/common/NewMySites';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import HeaderStore from '../HeaderCommon/HeaderStore';
import IconArrow from './icons/IconArrow';
import IconLogo from './icons/IconLogo';
import IconSearch from './icons/IconSearch';
import IconUser from './icons/IconUser';
//import DropBoxPc from './DropBox/pc';
//import DropBoxH5 from './DropBox/h5';
import getLocal from '@/app/utils/getLocal';
import { getLogoUrl } from '@/components/headerN/comp/headutil';
import Link from '@/components/utils/Link2';
import { loginUser } from '@/utils/cookie';
import gtm from '@/utils/gtm';
import styles from './index.module.scss';
import PopoverPanel from './PopoverPanel';
import { checkIsWithinTime, clearExpiredRecord } from './time';
import ToggleIcon from './ToggleIcon';

const DropBoxPc = dynamic(() => import('./DropBox/pc'), { ssr: false });
const DropBoxH5 = dynamic(() => import('./DropBox/h5'), { ssr: false });

const UserIcon = ({ firstEnterRef }: any) => {
    const [isMobile, setIsMobile] = useState(false);
    const userInfo = loginUser();
    const { query, pathname, locale } = getLocal();
    const router = useRouter();

    const [popoverOpen, setPopoverOpen] = useState(false);

    // 端点击个人中心
    const enterUserHandle = () => {
        if (userInfo?.token) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'top_function',
                button_name: 'Brand_My Account_Logged in',
            });
            if (NewMySites.includes(locale)) {
                router.push('/my-overview');
            } else {
                router.push('/my');
            }
        } else {
            gtm.push({
                event: 'uaEvent',
                event_name: 'top_function',
                button_name: 'Brand_My Account_Logged out',
            });
            // 跳转链接添加inviCode
            const { inviteCode } = query;
            const concatInviCode = inviteCode && pathname === '/referral-rewards';
            router.push(`/login${concatInviCode ? `?inviteCode=${inviteCode}&redirect=${encodeURIComponent(location.href)}` : ''}`);
        }
    };

    const handleClosePopover = () => {
        setPopoverOpen(false);
        firstEnterRef.current = true;
    };

    useEffect(() => {
        async function interceptAction() {
            if (pathname === '/' && !isMobile) return Promise.resolve(true);
            return Promise.resolve(false);
        }

        interceptAction().then((val) => {
            // 不在24小时内或者不符合弹窗规则，则跳过
            if (!val || checkIsWithinTime(locale)) {
                firstEnterRef.current = true;
                return;
            }

            // 清除超过24h的弹窗记录站点
            clearExpiredRecord();

            setPopoverOpen(val);
            setTimeout(() => {
                if (firstEnterRef && !firstEnterRef.current) {
                    handleClosePopover();
                }
            }, 10000);
        });
        if (typeof window === 'object') {
            setIsMobile(window.innerWidth <= 1024);
        }
    }, []);

    // 鼠标移入
    const enterHandle = () => {
        if (firstEnterRef && firstEnterRef.current) {
            setPopoverOpen(true);
        }
    };
    // 鼠标移出
    const outHandle = () => {
        if (firstEnterRef && firstEnterRef.current) {
            setPopoverOpen(false);
        }
    };

    if (!userInfo?.token && !isMobile) {
        if (!firstEnterRef.current) {
            return (
                <>
                    <button
                        className={`header-tools-item header-tools-item-user${popoverOpen ? ' header-tools-item-user-open' : ''}`}
                        aria-label="user"
                        onClick={enterUserHandle}
                        onMouseEnter={enterHandle}
                        onMouseLeave={outHandle}
                    >
                        <IconUser />
                        <dialog className={`${styles.userDialog} header-userDialog`}>
                            <div className="header-userDialog-in">
                                <PopoverPanel showClose={true} closePopover={handleClosePopover} />
                            </div>
                        </dialog>
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button
                        className={`header-tools-item header-tools-item-user${popoverOpen ? ' header-tools-item-user-open' : ''}`}
                        aria-label="user"
                        onClick={enterUserHandle}
                        onMouseEnter={enterHandle}
                        onMouseLeave={outHandle}
                    >
                        <IconUser />
                        <dialog className={`${styles.userDialog} header-userDialog`}>
                            <div className="header-userDialog-in">
                                <PopoverPanel showClose={false} />
                            </div>
                        </dialog>
                    </button>
                </>
            );
        }
    }

    return (
        <button className="header-tools-item header-tools-item-user" aria-label="user" onClick={enterUserHandle}>
            <IconUser />
        </button>
    );
};

const Header = ({ headerList, gloproductlist = [], children, ...rest }: any) => {
    const router = useRouter();
    const { locale } = getLocal();
    const logolink = getLogoUrl(locale);
    const firstEnterRef = useRef(false);
    const [isSSR, setIsSSR] = useState(true);
    const [isOpenPadWin, setIsOpenPadWin] = useState<boolean>(false);
    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
    const [isLoadH5Comp, setIsLoadH5Comp] = useState<boolean>(false);
    const [selectItemF, setSelectItemF] = useState<any>({});
    const firstMobileEnter = useRef(true); //手机端第一次进入,默认打开第1个
    const mouseLeaveFlagTimer = useRef<any>(1); // 定时器,为移出
    // const userInfo = loginUser();
    // console.log('Header List', headerList);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    const hiddenBodyScroll = () => {
        // 把body的滚动条隐藏
        if (typeof window === 'object') {
            document.body.style.overflow = 'hidden';
        }
    };

    const showBodyScroll = () => {
        // 把body的滚动条隐藏
        if (typeof window === 'object') {
            document.body.style.overflow = 'auto';
        }
    };

    // 电脑端鼠标移入一级菜单
    const mouseEnterFHandle = (item: any) => {
        const isPadWidth = getIsPadWidth();
        if (!isPadWidth) {
            // hiddenBodyScroll();
            // 2025.12.24 热区问题 停留超过0.5s再触发 https://ecoflow.feishu.cn/docx/RlvIdOMEWoYB6kxWCeUcGKR7nzg
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const timeId: NodeJS.Timeout = setTimeout(() => {
                setSelectItemF(item);
            }, 500);

            setTimeoutId(timeId);
        }
        clearTimerLeave();
    };

    // 移动端点击一级菜单
    const clickItemFHandle = (item: any) => {
        const isPadWidth = getIsPadWidth();
        if (isPadWidth) {
            //手机端
            setSelectItemF(item.name === selectItemF.name ? {} : item);
            setTimeout(() => {
                const target = document.querySelector('.ecoflow-frame-header .header-nav-item-out.is-active');
                // @ts-ignore
                const top = target?.offsetTop || 0;
                document.querySelector('.ecoflow-frame-header .is-open-win')?.scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }, 100);
        }
        if (!item.subs || !item.subs.length) {
            item.url && router.push(item.url);
        }
    };

    //鼠标移出头部导航,电脑端移除选中
    const mouseLeaveFHandle = () => {
        const timer = setTimeout(() => {
            const isPadWidth = getIsPadWidth();
            if (!isPadWidth) {
                if (selectItemF.name !== 'search') {
                    setSelectItemF({});
                }
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            }
            showBodyScroll();
        }, 100);
        mouseLeaveFlagTimer.current = timer;
    };

    // 清除移出时的定时器
    const clearTimerLeave = () => {
        clearTimeout(mouseLeaveFlagTimer.current);
    };

    // 点击搜索
    const searchHandle = () => {
        const isPadWidth = getIsPadWidth();
        if (isPadWidth) {
            hiddenBodyScroll();
        }
        setIsOpenSearch(true);
        setSelectItemF({
            name: 'search',
        });
    };

    // 点击搜索窗里的关闭
    const searchCloseHandle = () => {
        if (!isOpenPadWin) {
            showBodyScroll();
        }
        setIsOpenSearch(false);
        setSelectItemF({});
    };

    // 点击手机端的切换菜单
    const menuToggleCallback = (flag: boolean) => {
        // 第1次进入
        if (firstMobileEnter.current) {
            firstMobileEnter.current = false;
            // setSelectItemF(headerList?.detailList[0] || {}); // 改为默认关闭
            setIsLoadH5Comp(true);
        }
        const isPadWidth = getIsPadWidth();
        isPadWidth && setIsOpenPadWin(flag);
        // 把body的滚动条隐藏
        if (flag) {
            hiddenBodyScroll();
        } else {
            showBodyScroll();
        }
    };

    const hiddenLoginBtn = ['sa', 'tw', 'cn', 'cis', 'br'].includes(locale);
    const hiddenStoreBtn = ['sa', 'tw'].includes(locale);

    useEffect(() => {
        setIsSSR(false);
    }, []);

    return (
        <header className={`${styles.header} ${isOpenPadWin ? 'is-open-win' : ''} hidden-scrollbar`}>
            <div className={`header-inter${isOpenSearch ? ' is-open-search' : ''}`}>
                <div className={`${styles.headernav}`}>
                    <div className="header-content">
                        <div className="header-nav-l">
                            {/* @ts-ignore */}
                            <Link href={logolink} className="logoLink" dataLayer={{ event: 'uaEvent', event_name: 'top_function', button_name: 'Logo' }}>
                                <IconLogo />
                            </Link>
                        </div>
                        <div className="header-nav-main">
                            {headerList?.detailList?.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className={`header-nav-item-out ${item.name === selectItemF.name ? ' is-active' : ''} ${
                                        item?.subs?.length ? '' : 'none-child'
                                    }`}
                                >
                                    <button
                                        className="header-nav-item"
                                        name={item.name}
                                        aria-label={item.name}
                                        onMouseEnter={() => mouseEnterFHandle(item)}
                                        onMouseLeave={mouseLeaveFHandle}
                                        onClick={() => clickItemFHandle(item)}
                                    >
                                        <span>{item.name}</span>
                                        <IconArrow />
                                    </button>
                                    {isLoadH5Comp && <DropBoxH5 type={Number(item.type)} list={item.subs} />}
                                </div>
                            ))}
                        </div>
                        <div className={`${styles.headertools} header-tools`}>
                            <button className="header-tools-item" aria-label="search">
                                <IconSearch onClick={searchHandle} />
                            </button>
                            {!hiddenLoginBtn && !isSSR && <UserIcon firstEnterRef={firstEnterRef} />}
                            {/* {!hiddenStoreBtn && <Store locale={locale} />} */}
                            {!hiddenStoreBtn && <HeaderStore />}
                            <ToggleIcon callback={menuToggleCallback} />
                        </div>
                    </div>
                </div>
                <DropBoxPc
                    onMouseEnter={clearTimerLeave}
                    leaveHandle={mouseLeaveFHandle}
                    gloproductlist={gloproductlist}
                    selectItemF={selectItemF}
                    callback={searchCloseHandle}
                />
            </div>
        </header>
    );
};

export default Header;
