'use client';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';
import { useGlobal } from '@/utils/useGlobal';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import styles from '../../index.module.scss';

const i18nText: any = {
    en: {
        search_delete: 'Delete',
        site_nav_search: 'Search Ecoflow.com',
        site_nav_search_recommend: 'Recommendations',
        search_empty: 'No content～',
    },
    de: {
        search_delete: 'Löschen',
        site_nav_search: 'EcoFlow.com durchsuchen',
        site_nav_search_recommend: 'Empfehlungen',
        search_empty: 'Kein Inhalt～',
    },
    kr: {
        search_delete: 'Delete',
        site_nav_search: '검색하기 Ecoflow.com',
        site_nav_search_recommend: '추천 검색어',
        search_empty: '콘텐츠 없음',
    },
    jp: {
        search_delete: '削除',
        site_nav_search: '製品名などを入力してください',
        site_nav_search_recommend: 'おすすめ',
        search_empty: 'コンテンツなし',
    },
    br: {
        search_delete: 'Excluir',
        site_nav_search: 'Pesquisar Ecoflow.com',
        site_nav_search_recommend: 'Recomendações',
        search_empty: 'Nenhum conteúdo～',
    },
};

const DropBoxSearch = ({ callback, ...rest }: any) => {
    const router = useRouter();
    const { locale = '' } = getLocal();
    const [keyword, setKeyword] = useState<string | undefined>();
    const { gloproductlist = [] } = useGlobal();
    const filterOption = (inputValue: string, option: any) => option.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());

    const powerKitSeriesUrl = (generalKey: string) => {
        if (/^product_pk_/.test(generalKey)) {
            return 'ecoflow-power-kits/series';
        }
        return false;
    };

    const spuList = gloproductlist?.map((item: any) => {
        const url = powerKitSeriesUrl(item.generalKey);
        return {
            ...item,
            ...(url ? { url } : {}),
        };
    });

    const flatCategoryList = spuList?.map((item: any) => ({
        ...item,
        type: 'category',
        selectable: false,
    }));

    const filterAccessories = (spuList: any, flatCategoryList: any) => {
        if (!spuList.length) return [];
        const filterSpuList = spuList.filter((item: any) => !['product_ps_river_river_512', 'product_ps_river_river_768'].includes(item.generalKey));
        if (!flatCategoryList.length) return filterSpuList;
        return filterSpuList.filter((item: any) => item.accessoryFlag === false);
    };

    const filteredList = filterAccessories(spuList, flatCategoryList);

    const data = filteredList.map((spu: any) => ({
        ...spu,
        value: spu.spuId,
        text: spu.name,
    }));
    const list = data.filter((i: any) => (keyword !== undefined ? filterOption(`${keyword}`, i) : true));

    const closeFn = () => callback && callback();

    const clickSelect = (i: any) => {
        const value = i.sourceId;
        const seoByProductId = spuList?.find((item: any) => item.sourceId == value)?.url;
        const result = filteredList?.find((spu: any) => spu.spuId === value);
        gtm.push({
            event: 'uaEvent',
            event_name: 'search',
            // @ts-ignore
            search_term: document.querySelector('.searchbox-input')?.value || '',
            button_name: result.name,
            type: 'Click',
        });
        let itemUrl = `/${locale}/${seoByProductId}`;
        if (i.generalKey === 'product_pk_hub_5kva') {
            itemUrl = `/${locale}/power-kits-5kva-48v-power-system`;
        }
        closeFn();
        router.push(itemUrl);
    };

    //  点击回车
    const onEnterHandle = (e: any) => {
        e.preventDefault();
        if (e.keyCode === 13) {
            location.href = `/${locale}/search/${keyword}`;
        }
    };

    return (
        <div className={`${styles.searchbox} dropdown-inter`}>
            <div className="searchbox-h">
                <span className="searchbox-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11" r="6" stroke="#707070" strokeWidth="1.6" />
                        <path
                            d="M19.3841 20.5154C19.6965 20.8279 20.203 20.8279 20.5154 20.5154C20.8279 20.203 20.8279 19.6965 20.5154 19.3841L19.3841 20.5154ZM14.4343 15.5657L19.3841 20.5154L20.5154 19.3841L15.5657 14.4343L14.4343 15.5657Z"
                            fill="#707070"
                        />
                    </svg>
                </span>
                <input
                    type="text"
                    className="searchbox-input"
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyUp={onEnterHandle}
                    placeholder={i18nText[locale]?.site_nav_search || i18nText.en.site_nav_search}
                />
                <span className="searchbox-del" aria-label={i18nText[locale]?.search_delete || i18nText.en.search_delete} onClick={closeFn}>
                    {' '}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6 5L19 18M19 5L6 18" stroke="#707070" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </div>
            <div className="searchbox-c">
                <div className="searchbox-c-h">{i18nText[locale]?.site_nav_search_recommend || i18nText.en.site_nav_search_recommend}</div>
                <div className="searchbox-c-list">
                    {list.map((i: any) => {
                        if (i.url === '125w-bifacial-modular-solar-panel') {
                            return null;
                        }
                        return (
                            <button className="searchbox-c-item" key={i.value} onClick={() => clickSelect(i)}>
                                {i.text}
                            </button>
                        );
                    })}
                    {list.length === 0 && <button className="searchbox-c-item">{i18nText[locale]?.search_empty || i18nText.en.search_empty}</button>}
                </div>
            </div>
        </div>
    );
};

export default DropBoxSearch;
