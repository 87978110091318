import { loginUser } from '@/utils/cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import HeaderUserLogin from './HeaderUserLogin';
import HeaderUserNone from './HeaderUserNone';
import './index.scss';
export const HideUserLocaleList = ['cn', 'br', 'tw'];
export default function HeaderUser() {
    const { locale = 'us' } = useRouter();
    const [userInfo, setUserInfo] = useState<any>({});
    useEffect(() => {
        const userInfo2 = loginUser() || {};
        setUserInfo(userInfo2);
    }, []);
    if (HideUserLocaleList.includes(locale)) {
        return null;
    }
    return (
        <div className="HeaderUser HeaderV2-r-item">
            {userInfo?.token && (
                <>
                    <HeaderUserLogin />
                </>
            )}
            {!userInfo.token && (
                <>
                    <HeaderUserNone />
                </>
            )}
        </div>
    );
}
