import ToTopIcon2 from '@/components/UI/svg/Icons/ToTopIcon2';
import gtm from '@/utils/gtm';
import { useEffect, useRef } from 'react';
export default function BackToTop() {
    const refTop = useRef<any>(null);
    // 滚动到顶部
    const toTopFn = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
            gtm.push({
                event: 'uaEvent',
                event_name: 'bottom_function',
                button_name: 'back_to_top',
            });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const pageYOffset = window.pageYOffset;
            if (pageYOffset > 100) {
                refTop.current && refTop.current.classList && refTop.current.classList.add('show-top');
            } else {
                refTop.current && refTop.current.classList && refTop.current.classList.remove('show-top');
            }
        });
    }, []);
    return (
        <div className="ecoflow-frame-totop ant-back-top" ref={refTop} onClick={toTopFn}>
            <ToTopIcon2 />
        </div>
    );
}
