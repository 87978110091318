// 用户头像
export default function HeaderIconUser() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25ZM12 13.75C9.37665 13.75 7.25 15.8766 7.25 18.5C7.25 18.5773 7.23831 18.6518 7.2166 18.722C8.56443 19.6838 10.2161 20.25 12 20.25C13.7839 20.25 15.4356 19.6838 16.7851 18.7213C16.7617 18.6518 16.75 18.5773 16.75 18.5C16.75 15.8766 14.6234 13.75 12 13.75ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 14.1032 4.53703 16.0226 5.83265 17.4798C6.17234 15.4132 7.52481 13.6884 9.36333 12.8317C8.38018 12.0544 7.75 10.8508 7.75 9.5C7.75 7.15279 9.65279 5.25 12 5.25C14.3472 5.25 16.25 7.15279 16.25 9.5C16.25 10.8508 15.6198 12.0544 14.6374 12.8329C16.4752 13.6884 17.8277 15.4132 18.1671 17.4792C19.463 16.0226 20.25 14.1032 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM12 6.75C10.4812 6.75 9.25 7.98122 9.25 9.5C9.25 11.0188 10.4812 12.25 12 12.25C13.5188 12.25 14.75 11.0188 14.75 9.5C14.75 7.98122 13.5188 6.75 12 6.75Z"
                fill="#010101"
            />
        </svg>
    );
}
