import editorEventsBus from 'lowcode/utils/editorEventsBus';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
const SignupPopup: any = dynamic(() => import('@/components/Popups/SignupPopup'), { ssr: false });

export default function SignupPopupOut() {
    const router = useRouter();
    const { locale = 'us' } = router;
    const [winKey, setWinKey] = useState(1); // 其它页面能主动打开,用于重新渲染
    let SIGNUP_POPUP_SITES: string[] = ['au', 'jp', 'za', 'us', 'ca']; //按kr要求临时下掉kr
    const EU_SITE = ['eu', 'uk', 'de', 'fr', 'it', 'es'];
    const END_TIME = new Date('2024/03/25 16:00:00 GMT+8');
    const CURRENT_TIME = new Date();
    if (CURRENT_TIME > END_TIME) {
        SIGNUP_POPUP_SITES = SIGNUP_POPUP_SITES.concat(EU_SITE);
    }
    const ALLOW_ROUTERS: any = {
        en: ['/'],
        jp: ['/'],
        kr: ['/'],
        za: ['/'],
        us: ['/'],
        ca: ['/'],
    };
    const flag1 = SIGNUP_POPUP_SITES.includes(locale); //是否是该站点
    const flag2 = (ALLOW_ROUTERS[locale] || ALLOW_ROUTERS.en).includes(router.pathname) || (ALLOW_ROUTERS[locale] || ALLOW_ROUTERS.en).includes(router.asPath); //是否是指定页面
    const [isShowA, setIsShowA] = useState(flag1 && flag2); // 其它页面能主动打开
    const [isShowB, setIsShowB] = useState(false); // 用于延期打开
    const [statu2, setStatu2] = useState(0); //弹窗的初始状态

    useEffect(() => {
        // 右侧切换子元素
        editorEventsBus.on('globalWin-SignupPopup', () => {
            setIsShowA(true);
            setWinKey(Math.random());
            setStatu2(1);
        });
        const initHandleB = () => {
            const now = new Date().getTime();
            const storageTime = Number(localStorage.getItem(`popup_subscribe_${locale}`));
            // 24小时内只自动弹窗一次 页面打开3秒后自动弹出 => 改为10s
            if (!storageTime || now - storageTime > 24 * 60 * 60 * 1000) {
                setTimeout(() => {
                    setIsShowB(true);
                    setStatu2(1);
                    localStorage.setItem(`popup_subscribe_${locale}`, String(new Date().getTime()));
                }, 20 * 1000);
            }
        };
        initHandleB();
    }, []);

    return <Fragment key={winKey}>{isShowA && isShowB && <SignupPopup statu2={statu2} />}</Fragment>;
}
