import EcoImg from 'eco-design/base/eco-img';
import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

// 图文平铺模式
export default function NavType4({ subs = [] }: { subs?: any }) {
    const prefixCls = 'HeaderV2-NavType4-item';
    const { locale = 'us' } = useRouter();
    const shopifyOrigin = `https://${locale}.ecoflow.com`;
    return (
        <div className="HeaderV2-NavType4">
            {subs?.map((item: any, index: number) => {
                const itemUrl = item.url || '';
                const itemTitle = item.heading || '';
                // 埋点-108-buy
                const dataLayer_108 = {
                    event: 'uaEvent',
                    event_name: 'buy',
                    position: 'Collections_navigationBar',
                    product_name: itemTitle,
                };
                // 埋点-107-select_product
                const dataLayer_107 = {
                    event: 'uaEvent',
                    event_name: 'select_product',
                    list_name: 'none',
                    product_name: itemTitle,
                    button_name: `${itemTitle}_navigationBar`,
                };
                const dataLayer_card = itemUrl.includes(shopifyOrigin) ? dataLayer_108 : dataLayer_107;
                return (
                    <Fragment key={index}>
                        <EcoLink className={prefixCls} href={item.url} dataLayer={dataLayer_card}>
                            <EcoImg pc={item.pictureUrl} className={`${prefixCls}-img`} />
                            <div className={`${prefixCls}-msg`}>
                                <div className={`${prefixCls}-title`}>{item.heading}</div>
                                <div className={`${prefixCls}-desc`}>{item.subtitle}</div>
                            </div>
                        </EcoLink>
                    </Fragment>
                );
            })}
        </div>
    );
}
