'use client';
export default function HeaderIconStore({ ...props }: any) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="HeaderV2-r-item-store-icon">
            <path
                d="M20 12V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V12"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M14 16H10V21H14V16Z" stroke="#010101" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 4H15V9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9V4Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.49999 4H8.99999V8.5C8.99999 10.433 7.43299 12 5.49999 12C4.23578 12 3.21094 10.9752 3.21094 9.71095C3.21094 9.39515 3.27628 9.08277 3.40286 8.79345L5.49999 4Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 4H18.5L20.5971 8.79345C21.1039 9.95166 20.5757 11.3014 19.4175 11.8081C19.1282 11.9347 18.8158 12 18.5 12C16.567 12 15 10.433 15 8.5V4Z"
                stroke="#010101"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
