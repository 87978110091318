import { useState, useEffect } from 'react';
import css from './input.module.scss';
import EFArrowDown from '@/components/UI/svg/Icons/EFArrowDown';

interface EFSelectProps {
    value: string;
    list: Array<string>;
    onChange?: (_: any) => void;
}

interface ItemProps {
    key: string;
    val: string;
}

function getItemFromList(value: any, list: any) {
    let ret = null;

    list?.forEach((item: ItemProps) => {
        if (value === item.key) {
            ret = item;
        }
    });
    if (!ret) {
        ret = {
            key: value,
            val: value,
        };
    }
    return ret;
}

function EFSelect({ value, list, onChange }: EFSelectProps) {
    const defaultItem = getItemFromList(value, list);
    const [visible, setVisible] = useState<boolean>(false);
    const [selItem, setSelItem] = useState<ItemProps>(defaultItem);

    const toggleSelect = () => {
        const flag = !visible;
        setVisible(flag);
    };

    const selectItem = (item: ItemProps) => {
        setSelItem(item);
        setVisible(false);
        onChange && onChange(item.key);
    };

    useEffect(() => {
        const defaultItem = getItemFromList(value, list);
        setSelItem(defaultItem);
    }, [value, list]);

    return (
        <div className={`${css.efSelect} efSelect ${visible ? 'ef-select-open' : ''}`}>
            <div className="ef-select-selector" onClick={toggleSelect}>
                {selItem && selItem.val}
            </div>
            <EFArrowDown className="ef-select-icon" />
            <ul className="ef-select-list">
                {list.map((item: any, index: number) => (
                    <li className="ef-select-list-item" key={index} onClick={() => selectItem(item)}>
                        {item.val}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default EFSelect;
