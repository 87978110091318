import classNames from 'classnames';
import { useState } from 'react';
import DropBoxSearch from '../Header/DropBox/DropBoxSearch';
import HeaderLogo from '../HeaderCommon/HeaderLogo';
import HeaderSearch from '../HeaderCommon/HeaderSearch';
import HeaderStore from '../HeaderCommon/HeaderStore';
import HeaderToggleNav from '../HeaderCommon/HeaderToggleNav';
import HeaderUser from '../HeaderCommon/HeaderUser';
import HeaderNav from './HeaderNav';
import './HeaderV2.scss';
export default function HeaderV2({ headerList, gloproductlist }: { headerList?: any; gloproductlist?: any }) {
    const detailList = headerList?.detailList || [];
    const [searchOpen, setSearchOpen] = useState(false); //search
    const compCls = classNames('HeaderV2', {
        [`HeaderV2-search-open`]: searchOpen,
    });
    // search-打开
    const searchOpenHandle = () => {
        setSearchOpen(true);
    };
    // search-关闭
    const searchHideHandle = () => {
        setSearchOpen(false);
    };

    return (
        <div className={compCls}>
            <div className="HeaderV2-content">
                <div className="HeaderV2-l">
                    <HeaderLogo />
                </div>
                <HeaderNav detailList={detailList} />
                <div className="HeaderV2-r">
                    <HeaderSearch callback={searchOpenHandle} />
                    <HeaderUser />
                    <HeaderStore />
                    <HeaderToggleNav />
                </div>
            </div>
            {searchOpen && (
                <div className="HeaderV2-search">
                    <DropBoxSearch gloproductlist={gloproductlist} callback={searchHideHandle} />
                </div>
            )}
        </div>
    );
}
