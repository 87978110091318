'use client';

export default function EcoIconClose({ className, ...reset }: { className?: string }) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className={`bh-icon-close${className ? ` ${className}` : ''}`}
            {...reset}
        >
            <path d="M15 5L5 15" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 5L15 15" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
