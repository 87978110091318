import FooterN from '@/app/eflayout/Footer';
import GlobalCheck from '@/app/eflayout/GlobalCheck';
import GloBarTop from '@/app/eflayout/GloBar/GloBarTop';
import GloCart from '@/app/eflayout/GloCart';
import HeaderN from '@/app/eflayout/Header';
import HeaderV2 from '@/app/eflayout/HeaderV2';
import RegionPopup from '@/components/Popups/RegionPopup';
import { useGlobal } from '@/utils/useGlobal';
import CheckInCountdown from '@elem/ui/ef-check-in-activities/CheckInCountdown';
import BhStatCommon from 'lowcode/BhStat/BhStatCommon';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC } from 'react';
import SignupPopupOut from '../Popups/SignupPopup/SignupPopupOut';
import BackToTop from './BackToTop';
import styles from './index.module.scss';
import { newHeaderLocales } from './newHeaderLocales';

//const HeaderN: any = dynamic(() => import('@/app/eflayout/Header'), { ssr: true });
const HeaderO: any = dynamic(() => import('@/components/headerN/header'), { ssr: false });
const FooterO: any = dynamic(() => import('@/components/footerN/footer'), { ssr: false });

type PageLayout = {
    title?: string;
    children?: React.ReactNode;
    className?: string;
    style?: object;
    theme?: string;
    hideHeader?: boolean;
    hideFooter?: boolean;
    isNoFixed?: boolean;
    navChildren?: React.ReactNode;
    hideToTop?: boolean;
    gloBarObjectPage?: any;
};

const PageLayout: FC<PageLayout> = ({
    children,
    navChildren,
    theme = '',
    isNoFixed,
    hideHeader,
    hideFooter,
    hideToTop,
    gloBarObjectPage,
    className = '',
    ...ohterProps
}) => {
    const { headerList = {}, bottomList, gloBarObject } = useGlobal();
    const headerVersion = headerList?.version;

    const isShowHeaderV2 = headerVersion === 'V2';
    const router = useRouter();
    const { locale = 'us' } = router;
    const showNewHeader = newHeaderLocales.includes(locale);
    const gloBarObject2 = gloBarObjectPage || gloBarObject;

    return (
        <div className={`${styles['pagelayout-bd']} page-layout ${isNoFixed ? 'ecoflow-isNoFixed' : ''} ${className}`} {...ohterProps}>
            {!hideHeader && (
                <div className="ecoflow-frame-header">
                    <RegionPopup />
                    <GloBarTop gloBarObject={gloBarObject2.__globar_top} />
                    {showNewHeader && (
                        <>
                            {!isShowHeaderV2 && <HeaderN headerList={headerList} />}
                            {isShowHeaderV2 && <HeaderV2 headerList={headerList} />}
                        </>
                    )}
                    {!showNewHeader && <HeaderO />}
                </div>
            )}
            {navChildren}
            <section className="ecoflow-frame-main allContentContainer" id="allContentContainer" role="main">
                {children}
            </section>
            {!hideFooter && !showNewHeader && <FooterO theme={theme} />}
            {!hideFooter && showNewHeader && <FooterN bottomList={bottomList} theme={theme} />}
            {!hideToTop && <BackToTop />}
            <GloCart />
            <GlobalCheck />
            <CheckInCountdown />
            <BhStatCommon />
            <SignupPopupOut />
        </div>
    );
};

export default PageLayout;
