import { getIsPadWidth } from '@elem/utils/devices';
import classNames from 'classnames';
import EcoIconClose from 'eco-design-micro/icons/eco-icon-close';
import EcoLink from 'eco-design/base/eco-link';
import { Fragment, useRef, useState } from 'react';
import NavType1 from './NavType1';
import NavType2 from './NavType2';
import NavType3 from './NavType3';
import NavType4 from './NavType4';

export default function HeaderNav({ detailList }: { detailList?: any }) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const timerNumber = 400;
    const mouseLeaveTimer = useRef<any>(1);
    const mouseEnterTimer = useRef<any>(1);
    const [isOpenFlagA, setIsOpenFlagA] = useState(false); //用于性能优化

    // 点击打开 item
    const toggleItemHandle = (item: any, index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    };

    //鼠标移出头部导航,电脑端移除选中
    const mouseLeaveFPc = () => {
        const isPad = getIsPadWidth();
        if (!isPad) {
            mouseLeaveFHandle();
        }
    };
    const mouseLeaveFHandle = () => {
        mouseLeaveTimer.current = setTimeout(() => {
            clearTimeout(mouseEnterTimer.current);
            setActiveIndex(-1); //关闭
        }, timerNumber - 100);
    };

    // 电脑端鼠标移入一级菜单
    const mouseEnterFHandle = (index: number) => {
        clearTimeout(mouseLeaveTimer.current);
        mouseEnterTimer.current = setTimeout(() => {
            setActiveIndex(index);
            setIsOpenFlagA(true);
        }, 300);
    };
    // 清除移出
    const clearTimerHandle = () => {
        clearTimeout(mouseEnterTimer.current);
        clearTimeout(mouseLeaveTimer.current);
    };

    return (
        <div className="HeaderV2-nav">
            {detailList.map((item: any, index: number) => {
                const prefixCls = 'HeaderV2-nav-item';
                const subs = item.subs || [];
                const type = item.type; //5:单链接样式;4:图文平铺样式;3:文字平铺样式;2:场景样式;1:产品样式
                const hasChild = type !== 5;
                const compCls = classNames(prefixCls, {
                    [`${prefixCls}-none-child`]: !hasChild,
                    [`${prefixCls}-open`]: activeIndex === index,
                });

                return (
                    <Fragment key={index}>
                        {hasChild && (
                            <div className={compCls} onMouseEnter={() => mouseEnterFHandle(index)}>
                                <button
                                    className={`${prefixCls}-button`}
                                    onClick={() => {
                                        toggleItemHandle(item, index);
                                    }}
                                    aria-label={item.name}
                                    onMouseLeave={mouseLeaveFPc}
                                >
                                    {item.name}
                                    <span className="HeaderV2-nav-item-icon"></span>
                                </button>
                                <div className={`${prefixCls}-list`} onMouseEnter={clearTimerHandle} onMouseLeave={mouseLeaveFPc}>
                                    <div className={`${prefixCls}-list-h ef-content`}>
                                        <EcoIconClose className={`${prefixCls}-list-close`} onClick={mouseLeaveFHandle} />
                                    </div>
                                    {isOpenFlagA && (
                                        <div className={`${prefixCls}-list-content`}>
                                            {type === 1 && <NavType1 subs={subs} />}
                                            {type === 2 && <NavType2 subs={subs} />}
                                            {type === 3 && <NavType3 subs={subs} firstName={item.name} />}
                                            {type === 4 && <NavType4 subs={subs} />}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {!hasChild && (
                            <EcoLink
                                href={item.url}
                                className={prefixCls}
                                dataLayer={{
                                    event: 'uaEvent',
                                    event_name: 'top_first_navigation',
                                    navigation_name: `${item.name}_navigationBar`,
                                }}
                            >
                                <div className={`${prefixCls}-button`}>{item.name}</div>
                            </EcoLink>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
}
