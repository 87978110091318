import { getIsPadWidth } from '@elem/utils/devices';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import NavSceneA from '../NavSceneA';

// 把所有的数据整理成4层
const getSubsAHandle = (subsA: any[]) => {
    const subsB = JSON.parse(JSON.stringify(subsA)); //这是2级
    const result = [];

    //遍历2级中
    for (let i = 0; i < subsB.length; i++) {
        const itemB = subsB[i] || {};
        const itemC = itemB.subs || []; //这是3级
        const itemCLength = itemC.length;
        // 左侧没有2级(1)-没有子级
        if (!itemCLength) {
            result.push({
                ...itemB,
                noneSecond: true,
            });
            continue;
        }
        // 在2级配置了更多的入口
        const otherEntrances = itemB.otherEntrances || [];
        const isOtherEntrancesFlag = otherEntrances.length && itemB.otherEntranceFlag;
        if (isOtherEntrancesFlag) {
            result.push({
                ...itemB,
                noneSecond: true,
            });
            continue;
        }

        //  是否有5级
        let isHadLevel5 = false;
        //遍历3级中
        for (let j = 0; j < itemCLength; j++) {
            const itemCItem = itemC[j] || {};
            const itemD = itemCItem.subs || []; //4级
            // 在 4 级中寻找
            const hasLevel5 = itemD.find((subItem: any) => {
                const subsA = subItem.subs || [];
                return subsA.length;
            });
            if (hasLevel5) {
                isHadLevel5 = true; //2级下的某个子项拥有5级
                break;
            }
        }
        if (!isHadLevel5) {
            result.push({
                ...itemB,
                noneSecond: true,
            });
            continue;
        }
        result.push(itemB);
    }
    return result;
};

export default function NavType1({ subs = [] }: { subs?: any }) {
    const prefixCls1 = 'HeaderV2-NavType1-item1';
    const prefixCls2 = 'HeaderV2-NavType1-sub1';
    const [activeIndex1, setActiveIndex1] = useState(0);
    const [activeIndex2, setActiveIndex2] = useState(0);
    console.log(1122);
    const subsA = getSubsAHandle(subs);
    const defaultItem1 = subsA[0] || {};
    const defaultItem1Subs = defaultItem1?.subs || [];
    const defaultItem2 = defaultItem1Subs[0] || {};
    const defaultItem3 = defaultItem1.noneSecond ? defaultItem1 : defaultItem2;
    const [activeRItem, setActiveRItem] = useState(defaultItem3);
    const [isMobileA, setIsMobileA] = useState(true);
    //console.log('subs', subs);
    // 打开-1(左侧有2级)
    const toggleItemA1Handle = (item1: any, index1: number) => {
        const isPadWidth = getIsPadWidth();

        if (isPadWidth) {
            if (index1 === activeIndex1) {
                setActiveIndex1(-1);
            } else {
                setActiveIndex1(index1);
                setActiveIndex2(0);
            }
        } else {
            setActiveIndex1(index1);
        }
        setIsMobileA(isPadWidth);
    };
    // 打开-1(左侧没有2级)
    const toggleItemA2Handle = (item1: any, index1: number) => {
        setActiveRItem(item1);
        const isPadWidth = getIsPadWidth();

        if (isPadWidth) {
            if (index1 === activeIndex1) {
                setActiveIndex1(-1);
            } else {
                setActiveIndex1(index1);
            }
        } else {
            setActiveIndex1(index1);
        }
        setIsMobileA(isPadWidth);
    };
    // 打开-2
    const toggleItem2Handle = (item1: any, index1: number) => {
        setActiveRItem(item1);
        const isPadWidth = getIsPadWidth();

        if (isPadWidth) {
            if (index1 === activeIndex2) {
                setActiveIndex2(-1);
            } else {
                setActiveIndex2(index1);
            }
        } else {
            setActiveIndex2(index1);
        }
        setIsMobileA(isPadWidth);
    };
    useEffect(() => {
        setIsMobileA(getIsPadWidth());
    }, []);

    return (
        <div className="HeaderV2-NavType1">
            <div className="HeaderV2-NavType1-l">
                {subsA?.map((item1: any, index1: number) => {
                    const listA = item1.subs || [];
                    const noneSecond = item1.noneSecond; //左侧没有2级目录
                    const compCls = classNames(prefixCls1, {
                        [`${prefixCls1}-open`]: activeIndex1 === index1,
                        [`${prefixCls1}-none-second`]: noneSecond,
                    });
                    return (
                        <Fragment key={index1}>
                            {!noneSecond && (
                                <div className={compCls}>
                                    <div
                                        className={`${prefixCls1}-title`}
                                        onClick={() => {
                                            toggleItemA1Handle(item1, index1);
                                        }}
                                    >
                                        {item1.name}
                                        <span className="HeaderV2-nav-item-icon"></span>
                                    </div>
                                    <ul className={`${prefixCls2}`}>
                                        {listA.map((item2: any, index2: number) => {
                                            const compCls = classNames({
                                                [`${prefixCls2}-item`]: true,
                                                [`${prefixCls2}-item-open`]: activeIndex2 === index2,
                                            });
                                            return (
                                                <Fragment key={`${index1}-${index2}`}>
                                                    <li className={compCls}>
                                                        <div
                                                            className={`${prefixCls2}-title`}
                                                            onMouseEnter={() => {
                                                                toggleItem2Handle(item2, index2);
                                                            }}
                                                            onClick={() => {
                                                                toggleItem2Handle(item2, index2);
                                                            }}
                                                        >
                                                            {item2.name}
                                                            <span className="HeaderV2-nav-item-icon"></span>
                                                        </div>
                                                        {isMobileA && <NavSceneA item={item2} className={'HeaderV2-NavSceneA-h5'} />}
                                                    </li>
                                                </Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            {noneSecond && (
                                <div className={compCls}>
                                    <div
                                        className={`${prefixCls1}-title`}
                                        onClick={() => {
                                            toggleItemA2Handle(item1, index1);
                                        }}
                                    >
                                        {item1.name}
                                        <span className="HeaderV2-nav-item-icon"></span>
                                    </div>
                                    {isMobileA && <NavSceneA item={item1} className={'HeaderV2-NavSceneA-h5'} />}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </div>
            <div className="HeaderV2-NavType1-r">{!isMobileA && <NavSceneA item={activeRItem} className={'HeaderV2-NavSceneA-pc'} />}</div>
        </div>
    );
}
