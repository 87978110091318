'use client';
import classNames from 'classnames';
import { useState } from 'react';
import ToggleNavIcon from './ToggleNavIcon';
import './index.scss';
const HeaderToggleNav = () => {
    const [isOpen, setIsOpen] = useState(false);

    // 手机端切换
    const toggleFn = () => {
        const isOpen2 = !isOpen;
        setIsOpen(isOpen2);
    };
    const compCls = classNames('HeaderToggleNav', {
        [`HeaderToggleNav-open`]: isOpen,
    });
    return (
        <div className={compCls} onClick={toggleFn}>
            <div className="HeaderToggleNav-item HeaderToggleNav-item1"></div>
            <div className="HeaderToggleNav-item HeaderToggleNav-item2"></div>
            <div className="HeaderToggleNav-item HeaderToggleNav-item3"></div>
            <ToggleNavIcon className="HeaderToggleNav-bg" />
        </div>
    );
};

export default HeaderToggleNav;
