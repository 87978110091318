import EcoLink from 'eco-design/base/eco-link';
import { Fragment } from 'react';

export default function NavType3({ subs = [], firstName }: { subs?: any; firstName?: string }) {
    const prefixCls = 'HeaderV2-NavType3-item';

    return (
        <div className="HeaderV2-NavType3">
            {subs?.map((item1: any, index1: number) => {
                const listA = item1.subs || [];
                if (listA.length === 0) {
                    return null;
                }
                return (
                    <Fragment key={index1}>
                        <div className={prefixCls}>
                            <div className={`${prefixCls}-title`}>{item1.name}</div>
                            <ul className={`${prefixCls}-list`}>
                                {listA.map((item2: any, index2: number) => {
                                    const dataLayer_5 = {
                                        event: 'uaEvent',
                                        event_name: 'top_third_navigation',
                                        navigation_name: `${firstName || 'none'}_${item1.name || 'none'}_${item2.heading}_navigationBar`,
                                    };
                                    return (
                                        <Fragment key={`${index1}-${index2}`}>
                                            <li className={`${prefixCls}-list-item`}>
                                                <EcoLink href={item2.url} className={`${prefixCls}-list-link`} dataLayer={dataLayer_5}>
                                                    {item2.heading}
                                                </EcoLink>
                                            </li>
                                        </Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
}
