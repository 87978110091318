export default function (props: any) {
    return (
        <svg
            width="13px"
            height="12px"
            viewBox="0 0 13 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="地域提示跳转" transform="translate(-1211.000000, -24.000000)" fillRule="nonzero">
                    <g id="编组-4备份" transform="translate(1202.000000, 12.000000)">
                        <g id="勾4" transform="translate(9.500000, 12.000000)">
                            <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="12" height="12"></rect>
                            <path
                                d="M10.9103086,2.63765625 C10.6216523,2.3341875 10.1484141,2.32848047 9.85334766,2.62476562 L4.7600625,7.73741016 L2.12956641,5.03315625 C1.85640234,4.75205859 1.39841016,4.76765625 1.10633203,5.06767969 C0.814265625,5.36775 0.799054688,5.83871484 1.07254688,6.11975391 L4.28799609,9.42525 C4.56108984,9.70596094 5.01914063,9.69036328 5.31116016,9.39035156 C5.37807422,9.32162109 5.42817188,9.24370312 5.46575391,9.16130859 C5.49466406,9.13967578 5.52350391,9.11915625 5.54969531,9.09257813 L10.8978164,3.7243125 C11.1932813,3.42773438 11.1986133,2.9415 10.9103672,2.63766797 L10.9103086,2.63766797 L10.9103086,2.63765625 Z"
                                id="路径"
                                fill="#DEDEDE"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
