export const i18nText: any = {
    en: {
        Hot: 'Hot',
        New: 'New',
        Sale: 'Sale',
    },
    cis: {
        Hot: 'Популярное',
        New: 'Новинка',
        Sale: 'Распродажа',
    },
    cn: {
        Hot: '热门',
        New: '新品',
        Sale: '折扣',
    },
    de: {
        Hot: 'Beliebt',
        New: 'Neu',
        Sale: 'Angebot',
    },
    es: {
        Hot: 'Popular',
        New: 'Nuevo',
        Sale: 'Oferta',
    },
    fr: {
        Hot: 'Populaire',
        New: 'Nouveau',
        Sale: 'Promo',
    },
    hk: {
        Hot: '熱銷',
        New: '新品',
        Sale: '折扣',
    },
    it: {
        Hot: 'Popolare',
        New: 'Nuovo',
        Sale: 'Promo',
    },
    jp: {
        Hot: '人気商品',
        New: '新発売',
        Sale: 'セール',
    },
    kr: {
        Hot: '인기상품',
        New: '새제품',
        Sale: '세일',
    },
    sa: {
        Hot: 'رائج',
        New: 'جديد',
        Sale: 'تخفيض',
    },
    tw: {
        Hot: '熱銷',
        New: '新品',
        Sale: '折扣',
    },
};
