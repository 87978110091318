'use client';
import getLocal from '@/app/utils/getLocal';
import Language from '@/components/Language';
import EFArrowDown from '@/components/UI/svg/Icons/EFArrowDown';
import Link from '@/components/utils/Link2';
import { loginUrl } from '@/service/util';
import { getIsLogin } from '@/utils/cookie';
import { getIsMobileWidth, getIsPadWidth } from '@elem/utils/devices';
import { getCookieUser } from 'lowcode/BhStat/utils/cookieFn';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import EmailForm from './comp/EmailForm';
import IconListUS from './comp/IconListUS';
import css from './footer.module.scss';
import { i18nText } from './i18nText';
import CCPAModal from './modalCCPA';

const SMSForm = dynamic(() => import('./comp/SMSForm'), {
    ssr: false,
});
const IconListKR = dynamic(() => import('./comp/IconListKR'), {
    ssr: false,
});
const IconListCN = dynamic(() => import('./comp/IconListCN'), {
    ssr: false,
});
const IconListRU = dynamic(() => import('./comp/IconListRU'), {
    ssr: false,
});
const IconListZa = dynamic(() => import('./comp/IconListZa'), {
    ssr: false,
});
const IconListAU = dynamic(() => import('./comp/IconListAU'), {
    ssr: false,
});

const getIconList = (locale: string) => {
    switch (locale) {
        case 'cn':
            return IconListCN;
        case 'kr':
            return IconListKR;
        case 'cis':
            return IconListRU;
        case 'za':
            return IconListZa;
        case 'au':
            return IconListAU;
        default:
            return IconListUS;
    }
};

export default function Footer({ bottomList = [], theme = '' }: { bottomList: any; theme: string }) {
    const [openkey, setOpenkey] = useState<string>('');
    const { locale = 'us' } = getLocal();
    const IconList = getIconList(locale);
    const [emailSubscription, setEmailSubscription] = useState('');
    const [smsSuccess, setSmsSuccess] = useState('');
    const [isCCPAModal, setIsCCPAModal] = useState(false);
    const [isH5] = useState(getIsMobileWidth);
    const [isLogin, setIsLogin] = useState(false);
    // 手机端切换显隐
    const toggleItem = (togglekey: string) => {
        const isMobile = getIsPadWidth(); //是否是移动端
        if (!isMobile) {
            return null;
        }
        let targetKey = togglekey;
        if (togglekey === openkey) {
            targetKey = '';
        }
        setOpenkey(targetKey);
    };
    const getIsAccredit = async () => {
        const userInfo = getCookieUser();
        const res = await fetch(`${loginUrl(locale)}/website/creditsDay/queryEngagementActivity?locale=${locale}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`,
                'x-appid': 1,
            },
        });
        const data = await res.json();
        return data.code !== '401';
    };
    return (
        <footer className={`${css.footer} ${theme} footer`}>
            <section className="footer-a">
                {bottomList?.detailList?.map((column: any, idx: number) => (
                    <div className={`fa-item${openkey == column.name ? ' opend' : ''}`} key={`${column.name}_${idx}`}>
                        <h3 onClick={() => toggleItem(column.name)}>{column.name}</h3>
                        <div className="fa-list">
                            {column.subs?.map((item: any, index2: number) => (
                                <Link
                                    href={item.url}
                                    key={`${item.heading}_${index2}`}
                                    dataLayer={{
                                        event: 'uaEvent',
                                        event_name: 'bottom_navigation',
                                        list_name: column.heading,
                                        navigation_name: item.heading,
                                    }}
                                >
                                    {item.heading}
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="fa-itemb fa-item-lang-h5">
                    <div className="fa-itemb-desc">{i18nText[locale]?.site_bottom_select_tip || i18nText.en.site_bottom_select_tip}</div>
                    <div className="fa-itemb-title">
                        <Language />
                        <span className="fa-itemb-icon column-arr-right">
                            <EFArrowDown />
                        </span>
                    </div>
                </div>
            </section>
            <section className="footer-b">
                <IconList locale={locale} theme={theme} />
                {emailSubscription && !smsSuccess ? (
                    <SMSForm locale={locale} email={emailSubscription} theme={theme} setSmsSuccess={setSmsSuccess} />
                ) : (
                    <EmailForm locale={locale} setEmailSubscription={setEmailSubscription} smsSuccess={smsSuccess} setSmsSuccess={setSmsSuccess} />
                )}
            </section>
            <section className="footer-c">
                <div className="footer-c-in">
                    <div className="left">
                        {i18nText[locale]?.site_bottom_copyright.replace('{nowYear}', new Date().getFullYear()) ||
                            i18nText.en.site_bottom_copyright.replace('{nowYear}', new Date().getFullYear())}
                        {locale === 'cn' && (
                            <Link
                                href={'https://beian.miit.gov.cn/#/Integrated/index'}
                                dataLayer={{
                                    event: 'uaEvent',
                                    event_name: 'bottom_navigation',
                                    navigation_name: '备案号',
                                }}
                            >
                                粤ICP备2020097871号
                            </Link>
                        )}
                        {locale === 'us' && (
                            <>
                                <div
                                    onClick={async () => {
                                        const isLogin1 = getIsLogin() && (await getIsAccredit());
                                        setIsLogin(isLogin1);
                                        setIsCCPAModal(true);
                                    }}
                                    className="left-ccpa"
                                >
                                    Do Not Share My Personal Information
                                </div>
                            </>
                        )}
                    </div>
                    <div className="right language-outbox fa-item-lang-pc">
                        {i18nText[locale]?.site_bottom_select_tip || i18nText.en.site_bottom_select_tip}
                        <Language />
                    </div>
                </div>
                {isCCPAModal && (
                    <CCPAModal
                        callback={() => {
                            setIsCCPAModal(false);
                        }}
                        isLogin={isLogin}
                    ></CCPAModal>
                )}
            </section>
        </footer>
    );
}
