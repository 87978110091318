import HeaderUserTipsContent from './ContentA';

// 没有登录
export default function HeaderUserTipsWin({ closeCallback }: { closeCallback?: any }) {
    return (
        <dialog className={`HeaderV2-UserTipsWin HeaderV2-user-win-pc`}>
            <HeaderUserTipsContent closeCallback={closeCallback} />
        </dialog>
    );
}
