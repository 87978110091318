'use client';
export default function HeaderIconSearch({ ...props }: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx="11" cy="11" r="6" stroke="black" strokeWidth="1.6" />
            <path
                d="M19.3841 20.5154C19.6965 20.8279 20.203 20.8279 20.5154 20.5154C20.8279 20.203 20.8279 19.6965 20.5154 19.3841L19.3841 20.5154ZM14.4343 15.5657L19.3841 20.5154L20.5154 19.3841L15.5657 14.4343L14.4343 15.5657Z"
                fill="black"
            />
        </svg>
    );
}
