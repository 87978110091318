import gtm from '@/utils/gtm';
import EcoIconClose from 'eco-design-micro/icons/eco-icon-close';
import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import './index.scss';

const useLang = () => {
    const { locale = 'us' } = useRouter();
    const langText: Record<string, { [key: string]: string }> = {
        au: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        us: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        ca: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        cis: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        cn: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        de: {
            desc: 'Sie erhalten 500 EcoCredits im Wert von 25 €',
            button: 'Jetzt registrieren',
            register_tip: 'Bereits Mitglied? ',
            login: 'Einloggen',
        },
        en: {
            desc: 'Get 500 EcoCredits, Worth $25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        es: {
            desc: 'Obtén 500 EcoCredits, con un valor de 25 €',
            button: 'Únete ahora',
            register_tip: '¿Ya tienes una cuenta? ',
            login: 'Inicia sesión',
        },
        eu: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        fr: {
            desc: "Obtenez 500 EcoCredits d'une valeur de 25 €",
            button: 'Rejoignez-nous maintenant',
            register_tip: 'Vous avez déjà un compte? ',
            login: 'Se connecter',
        },
        hk: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        it: {
            desc: 'Ricevi 500 EcoCredits del valore di 25 €',
            button: 'Unisciti ora',
            register_tip: 'Hai già un account? ',
            login: 'Accedi',
        },
        jp: {
            desc: '500 エコポイント をゲット',
            button: '今すぐ登録する',
            register_tip: 'すでにアカウントをお持ちですか？ ',
            login: 'ログイン',
        },
        kr: {
            desc: '500 에코포인트(₩35,000 상당)를 받으세요',
            button: '지금 가입하기',
            register_tip: '기존 사용자이신가요? ',
            login: '로그인',
        },
        sa: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        tw: {
            desc: 'Get 500 EcoCredits, Worth €25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        uk: {
            desc: 'Get 500 EcoCredits, Worth £25',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
        za: {
            desc: 'Get 500 EcoCredits, Worth R500',
            button: 'Join Now',
            register_tip: 'Existing user? ',
            login: 'Log in',
        },
    };
    const copies = langText[locale] || langText['us'];

    return {
        t: (text: string) => copies[text] || text,
    };
};
// 没有登录
export default function HeaderUserTipsContent({ closeCallback }: { closeCallback?: any }) {
    const { locale = 'us' } = useRouter();
    const { t } = useLang();
    const [registerUrl, setRegisterUrl] = useState('');
    const [loginUrl, setLoginUrl] = useState('');
    useEffect(() => {
        setRegisterUrl(`${window.location.origin}/${locale}/register?redirect=${encodeURIComponent(window.location.href)}`);
        setLoginUrl(`${window.location.origin}/${locale}/login?redirect=${encodeURIComponent(window.location.href)}`);
    }, []);
    const textButton = t('button');
    const textTips1 = t('register_tip');
    const textLogin = t('login');
    const closeHandle = () => {
        gtm.push({
            event: 'uaEvent',
            event_name: 'floating_close',
            button_name: 'Close',
            type: 'Authentication',
        });
        if (closeCallback) {
            closeCallback();
        }
    };
    return (
        <div className="HeaderV2-UserTipsWin-content">
            <div className="HeaderV2-UserTipsWin-line1">
                <TipsIconA />
                {t('desc')}
            </div>
            <EcoLink
                className="HeaderV2-UserTipsWin-line2"
                href={registerUrl}
                dataLayer={{
                    event: 'uaEvent',
                    event_name: 'authentication_button',
                    type: 'Redirect',
                    button_name: textButton,
                    url: registerUrl,
                }}
            >
                {textButton}
            </EcoLink>
            <div className="HeaderV2-UserTipsWin-line3">
                {textTips1}
                <EcoLink
                    className="HeaderV2-UserTipsWin-line3-link"
                    href={loginUrl}
                    dataLayer={{
                        event: 'uaEvent',
                        event_name: 'authentication_button',
                        type: 'Redirect',
                        button_name: textLogin,
                        url: loginUrl,
                    }}
                >
                    {textLogin}
                </EcoLink>
            </div>
            <EcoIconClose className="HeaderV2-UserTipsWin-close" onClick={closeHandle} />
        </div>
    );
}

const TipsIconA = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66666 8.00016L5.99999 11.3335L13.3333 4.3335" stroke="#30BF00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
    );
};
