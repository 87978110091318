import classNames from 'classnames';
import EcoImg from 'eco-design/base/eco-img';
import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
export default function NavType2({ subs = [] }: { subs?: any }) {
    const prefixCls1 = 'HeaderV2-NavType2-item';
    const prefixClsPc = 'HeaderV2-NavType2-sub-pc';
    const prefixClsH5 = 'HeaderV2-NavType2-sub-h5';
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeSubs, setActiveSubs] = useState(subs[0]?.subs || []);
    const { locale = 'us' } = useRouter();
    const shopifyOrigin = `https://${locale}.ecoflow.com`;
    // 切换子级
    const toggleItemHandle = (item: any, index: number) => {
        setActiveIndex(index);
        setActiveSubs(item.subs || []);
    };

    return (
        <div className="HeaderV2-NavType2">
            <div className="HeaderV2-NavType2-l">
                {subs?.map((item1: any, index1: number) => {
                    const listA = item1.subs || [];
                    const compCls = classNames(prefixCls1, {
                        [`${prefixCls1}-open`]: activeIndex === index1,
                    });
                    return (
                        <Fragment key={index1}>
                            <div className={compCls}>
                                <div
                                    className={`${prefixCls1}-title`}
                                    onMouseEnter={() => {
                                        toggleItemHandle(item1, index1);
                                    }}
                                    onClick={() => {
                                        toggleItemHandle(item1, index1);
                                    }}
                                >
                                    {item1.name}
                                </div>
                                <ul className={prefixClsH5}>
                                    {listA.map((item2: any, index2: number) => {
                                        const itemUrl = item2.url || '';
                                        const itemTitle = item2.heading || '';
                                        // 埋点-108-buy
                                        const dataLayer_108 = {
                                            event: 'uaEvent',
                                            event_name: 'buy',
                                            position: 'Collections_navigationBar',
                                            product_name: itemTitle,
                                        };
                                        // 埋点-107-select_product
                                        const dataLayer_107 = {
                                            event: 'uaEvent',
                                            event_name: 'select_product',
                                            list_name: item1.name || 'none',
                                            product_name: itemTitle,
                                            button_name: `${itemTitle}_navigationBar`,
                                        };
                                        const dataLayer_card = itemUrl.includes(shopifyOrigin) ? dataLayer_108 : dataLayer_107;
                                        return (
                                            <Fragment key={`${index1}-${index2}`}>
                                                <li className={`${prefixClsH5}-item`}>
                                                    <EcoLink href={item2.url} className={`${prefixClsH5}-link`} dataLayer={dataLayer_card}>
                                                        <EcoImg pc={item2.pictureUrl} className={`${prefixClsH5}-img`} />
                                                        <div className={`${prefixClsH5}-msg`}>
                                                            <div className={`${prefixClsH5}-title`}>{item2.heading}</div>
                                                            <div className={`${prefixClsH5}-desc`}>{item2.subtitle}</div>
                                                        </div>
                                                    </EcoLink>
                                                </li>
                                            </Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <div className="HeaderV2-NavType2-r">
                <ul className={`${prefixClsPc} ${prefixClsPc}-length-${activeSubs.length}`}>
                    {activeSubs.map((item2: any, index2: number) => {
                        return (
                            <Fragment key={`${index2}`}>
                                <li className={`${prefixClsPc}-item`}>
                                    <EcoLink href={item2.url} className={`${prefixClsPc}-link`}>
                                        <EcoImg pc={item2.pictureUrl} className={`${prefixClsPc}-img`} />
                                        <div className={`${prefixClsPc}-msg`}>
                                            <div className={`${prefixClsPc}-title`}>{item2.heading}</div>
                                            <div className={`${prefixClsPc}-desc`}>{item2.subtitle}</div>
                                        </div>
                                    </EcoLink>
                                </li>
                            </Fragment>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
