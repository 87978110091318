import EcoLink from 'eco-design/base/eco-link';

export default function CardMoreA({
    buttonUrl,
    buttonName,
    className,
    dataLayer,
}: {
    buttonUrl: string;
    buttonName?: string;
    className?: string;
    dataLayer?: any;
}) {
    return (
        <EcoLink className={`HeaderV2-CardMoreA ${className}`} href={buttonUrl} dataLayer={dataLayer}>
            <div className="HeaderV2-CardMoreA-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.8338 19.9993C34.8338 12.1753 28.4912 5.83268 20.6672 5.83268C12.8431 5.83268 6.50049 12.1753 6.50049 19.9993C6.50049 27.8234 12.8431 34.166 20.6672 34.166C28.4912 34.166 34.8338 27.8234 34.8338 19.9993ZM20.6672 4.16602C29.4117 4.16602 36.5005 11.2548 36.5005 19.9993C36.5005 28.7439 29.4117 35.8327 20.6672 35.8327C11.9226 35.8327 4.83382 28.7439 4.83382 19.9993C4.83382 11.2548 11.9226 4.16602 20.6672 4.16602ZM16.7446 13.9219C16.4191 13.5965 16.4191 13.0689 16.7446 12.7434C17.07 12.418 17.5976 12.418 17.9231 12.7434L24.5897 19.4101C24.9152 19.7355 24.9152 20.2632 24.5897 20.5886L17.9231 27.2553C17.5976 27.5807 17.07 27.5807 16.7446 27.2553C16.4191 26.9298 16.4191 26.4022 16.7446 26.0768L22.822 19.9993L16.7446 13.9219Z"
                        fill="#696C71"
                    />
                </svg>
            </div>
            {buttonName && <div className="HeaderV2-CardMoreA-text">{buttonName}</div>}
        </EcoLink>
    );
}
