import EcoLink from 'eco-design/base/eco-link';
import { useRouter } from 'next/router';
import IconLogo from '../../Header/icons/IconLogo';
export default function HeaderLogo() {
    const { locale = 'us' } = useRouter();
    return (
        <EcoLink href={`/${locale}`} className="logoLink" dataLayer={{ event: 'uaEvent', event_name: 'top_function', button_name: 'Logo' }}>
            <IconLogo />
        </EcoLink>
    );
}
