import GtmModuleData from 'eco-design-micro/GtmModuleData';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import BhStatCommonGa4 from './BhStatCommonGa4';

const isCypressTestFn = () => {
    if (typeof window !== 'undefined') {
        const userAgent = window?.navigator?.userAgent || '';
        const devices = userAgent.toLowerCase() || '';
        if (devices.indexOf('iscypresstest') > 0) {
            return true;
        }
    }
    return false;
};
const NEXT_PUBLIC_MODY = process.env.NEXT_PUBLIC_MODY;
export default function BhStatCommon() {
    const { locale = 'us' } = useRouter();
    const [openStatFlag, setOpenStatFlag] = useState(false);
    useEffect(() => {
        const prodFlag = NEXT_PUBLIC_MODY !== 'test' && ['sa'].includes(locale); //生产环境只开启部分站点
        const textFlag = NEXT_PUBLIC_MODY === 'test'; //测试环境开启所有站点
        const openFlag = prodFlag || textFlag;
        // 是否是自动化脚本
        const isCypressTest = isCypressTestFn();
        if (!isCypressTest && openFlag) {
            setOpenStatFlag(true);
        }
    }, []);
    return (
        <>
            {openStatFlag && (
                <>
                    <GtmModuleData />
                    <BhStatCommonGa4 />
                </>
            )}
        </>
    );
}
