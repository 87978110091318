import { loginUser } from '@/utils/cookie';
import gtm from '@/utils/gtm';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';
import { getIsPadWidth } from '@elem/utils/devices';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import HeaderIconStore from './HeaderIconStore';

const StoreCN = dynamic(() => import('../../Header/StoreCN'), {
    ssr: false,
});
const storeLangText: any = {
    us: {
        store: 'Store',
    },
    cn: {
        store: '商城',
    },
    it: {
        store: 'negozio',
    },
    de: {
        store: 'Geschäft',
    },
    fr: {
        store: 'magasin',
    },
    es: {
        store: 'tienda',
    },
    kr: {
        store: '스토어',
    },
    jp: {
        store: 'ストア',
    },
    sa: {
        store: 'متجر',
    },
};

export default function HeaderLogo() {
    const { locale = 'us' } = useRouter();
    const [showCnWin, setShowCnWin] = useState(false);
    const TextA = storeLangText[locale] || storeLangText['us'];
    // 发起点击
    const clickHandle = async () => {
        if (locale === 'cn') {
            gotoCnHandle();
        } else {
            gotoShopifyHandle1();
        }
        try {
            gtm.push({
                event: 'uaEvent',
                event_name: 'top_function',
                button_name: 'Store',
            });
        } catch (error) {}
    };
    // 去Shopify
    const gotoShopifyHandle1 = async () => {
        (async () => {
            const { authSignIn } = await import('@/service/login');
            gotoShopifyHandle2(authSignIn);
        })();
    };
    const gotoShopifyHandle2 = async (authSignIn: any) => {
        const isMobile = getIsPadWidth(); //是否是移动端
        let winOpen: any;
        //手机不使用window.open打开
        if (isMobile) {
        } else {
            winOpen = window.open('', '_blank');
        }
        try {
            let url = 'https://us.ecoflow.com';
            switch (locale) {
                case 'cn':
                    url = 'https://mall.jd.com/index-1000363402.html';
                    break;
                case 'jp':
                    url = 'https://jp.ecoflow.com';
                    break;
                case 'cis':
                    url = 'https://ecoflow-russia.com/';
                    break;
                default:
                    url = `https://${locale}.ecoflow.com`;
                    break;
            }
            const token = loginUser()?.token || '';

            if (token) {
                const res = await authSignIn({ url });
                if (res.code === '0') {
                    url = res.data;
                }
            }
            // 第三方shopify登录，需要在cookie写入token信息，目前没有选择地区，暂时写us地区
            document.cookie = `us-token=${token};expires=7;` + `domain=.ecoflow.com;path=/`;
            if (isMobile) {
                location.href = saveCookieForShopify(url, locale) || '';
            } else {
                winOpen.location = saveCookieForShopify(url, locale); //兼容safari浏览器
            }
        } catch (err) {
            console.log(err);
        }
    };
    // 去天猫
    const gotoCnHandle = () => {
        setShowCnWin(!showCnWin);
    };
    const compCls = classNames('HeaderV2-r-item HeaderV2-r-item-store', {
        [`HeaderV2-r-item-store-cn-open`]: showCnWin,
    });
    return (
        <div className={compCls} onClick={clickHandle}>
            <button aria-label="store" className="HeaderV2-r-item-store-btn">
                {TextA.store}
            </button>
            <HeaderIconStore />
            {locale === 'cn' && <StoreCN />}
        </div>
    );
}
