import HeaderIconSearch from './HeaderIconSearch';
export default function HeaderSearch({ callback }: { callback?: any }) {
    // 点击打开
    const openHandle = () => {
        if (callback) {
            callback();
        }
    };
    return (
        <>
            <button onClick={openHandle} className="HeaderV2-r-item HeaderV2-r-item-search" aria-label={'search'}>
                <HeaderIconSearch />
            </button>
        </>
    );
}
