'use client';
import EFSelect from '@/components/UI/EFSelect';
import { regionPopupI18n } from '@/constants/i18n';
import { localValueMap } from '@/utils/locals';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@/utils/localStorage';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import EFCorrectSelect from '../../UI/svg/Header/Icon/EFCorrectSelect';
import './index.scss';
const regionsAll = Object.values(localValueMap);

export default function RegionPopup({ callback }: any) {
    const { route, locale } = useRouter();

    const [region, setRegion] = useState({});
    const macIpCodeTemp = getLocalStorage('macIp') || '0';
    const macIpCode = macIpCodeTemp === 'gb' ? 'uk' : macIpCodeTemp;
    const [i18n, setI18n] = useState(regionPopupI18n.us);
    const [selected, setSelected] = useState(regionsAll.find((region: any) => region?.local === getLocalStorage('macIp')?.countryCode?.toLowerCase()));
    const [selectOpen, setSelectOpen] = useState(false);

    const onChange = useCallback(
        (k: any) => {
            if (k === '1') {
                setSelected({ local: '1', regionText: i18n?.choose });
                setRegion(regionsAll?.find((region: any) => region?.local?.toLowerCase() === macIpCode));
            } else {
                setSelected(regionsAll?.find((region: any) => region?.local?.toLowerCase() === macIpCode));
                setRegion({ local: '1', regionText: i18n?.choose });
            }
        },
        [i18n?.choose, macIpCode],
    );

    const onConfirm = useCallback(() => {
        // 如果选择国家则跳转多站点选择页面
        if (selected?.local === '1') {
            callback('3');
        } else {
            // 选择浏览器语言（第一选项），跳转所选语言站点首页；
            setLocalStorage(`IP_Redirect_${locale}`, selected?.local);
            const sItem = regionsAll?.find((region: any) => region?.local === selected?.local);
            removeLocalStorage('appInfo');
            setLocalStorage('languageRegion', `${sItem?.region}(${sItem?.language})`);
            setLocalStorage('appInfo', {
                appId: sItem?.appId,
                acceptLanguage: sItem?.acceptLanguage,
                locale: sItem?.local,
            });
            callback('4');
            if (locale === selected?.local && route === '/') {
                return;
            }
            // 跳转首页
            window.location.href = `${window.location.origin}/${selected?.local}`;
        }
    }, [callback, locale, route, selected?.local]);

    const onClose = useCallback(() => {
        setLocalStorage(`IP_Redirect_${locale}`, locale);
        callback('4');
    }, [callback, locale]);

    useEffect(() => {
        setI18n(regionPopupI18n[macIpCode] || regionPopupI18n['us']);
        let item = regionsAll?.find((region: any) => region?.local?.toLowerCase() === macIpCode);
        if (!item) {
            item = regionsAll?.find((region: any) => region?.local?.toLowerCase() === 'us');
        }
        setSelected(item);
        setRegion({
            local: '1',
            regionText: regionPopupI18n[macIpCode]?.choose || regionPopupI18n.us.choose,
        });
    }, [macIpCode]);

    return (
        <div className={`regionPopup Header-regionPopup`}>
            <div className="content">
                <div className="left">{i18n?.text}</div>
                <div className="right">
                    <div className={`selector ${selectOpen ? 'open' : ''}`}>
                        <div className="checkIcon">
                            <EFCorrectSelect />
                        </div>
                        <EFSelect
                            value={selected?.regionText}
                            list={[
                                {
                                    key: region?.local,
                                    val: region?.regionText,
                                },
                            ]}
                            onChange={onChange}
                        />
                    </div>
                    <div className="btn" onClick={onConfirm}>
                        {i18n?.confirm}
                    </div>
                </div>
                <div className="close" onClick={onClose}>
                    &times;
                </div>
            </div>
        </div>
    );
}
